/**
 * This helper retrieves size tokens for a particular size (extra-small, small, medium).
 *
 * @param size - The current size of the IconButton.
 *
 * @returns A new object containing the button and icon size.
 */
export const getSizeTokens = size => {
  // Base tokens for common properties across variants
  const sizeTokens = {
    medium: {
      buttonSize: 'icon-800',
      iconSize: 'icon-200'
    },
    small: {
      buttonSize: 'icon-700',
      iconSize: 'icon-100'
    },
    'extra-small': {
      buttonSize: 'icon-600',
      iconSize: 'icon-100'
    }
  };
  return sizeTokens[size];
};

/**
 * This helper retrieves size tokens for a particular size (extra-small, small, medium).
 *
 * @param shape - The current shape of the IconButton.
 *
 * @returns A new object containing the border radius.
 */
export const getShapeTokens = shape => {
  // Base tokens for common properties across variants
  const shapeTokens = {
    square: {
      borderRadius: '100'
    },
    circle: {
      borderRadius: '300'
    }
  };
  return shapeTokens[shape];
};