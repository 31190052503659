import { css } from 'styled-components';
export const setUiTransition = (property, timing) => {
  const computedTiming = timing || '150ms';
  const computedProperty = property || 'all';
  return css(["transition:", " ", " ease-out;"], computedProperty, computedTiming);
};
export const setInputAppearance = theme => {
  return Object.assign({
    display: 'block',
    height: theme.space['1000'],
    width: '100%'
  }, theme.typography['body-200'], {
    textAlign: 'left',
    verticalAlign: 'middle',
    color: theme.color['text-primary-default'],
    backgroundColor: theme.color['fill-surface-raised'],
    border: `1px solid ${theme.color['border-primary-default']}`,
    borderRadius: theme.borderRadius['100']
  });
};