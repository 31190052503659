import { useTheme } from 'styled-components';
export const useSizeCssObject = sizeTokens => {
  const theme = useTheme();
  return {
    width: theme.size[sizeTokens.buttonSize],
    height: theme.size[sizeTokens.buttonSize],
    svg: {
      width: theme.size[sizeTokens.iconSize],
      height: theme.size[sizeTokens.iconSize]
    }
  };
};
export const useShapeCssObject = shapeTokens => {
  const theme = useTheme();
  return {
    borderRadius: theme.borderRadius[shapeTokens.borderRadius]
  };
};