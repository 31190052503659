export const useToColorMapping = use => {
  switch (use) {
    case 'candy-apple':
      return 'fill-accent-red-default';
    case 'calypso':
      return 'fill-accent-blue-default';
    case 'koala':
      return 'fill-tertiary-default';
    case 'marigold':
      return 'fill-accent-yellow-default';
    case 'norman':
      return 'fill-accent-magenta-default';
    case 'sorbet':
      return 'fill-accent-light-orange-default';
    case 'pantera':
      return 'fill-accent-neutral-default';
    case 'thunderdome':
    case 'beta':
      return 'fill-accent-purple-default';
    case 'lorax':
    case 'free':
      return 'fill-accent-orange-default';
    case 'oz':
    case 'new':
    default:
      return 'fill-accent-green-default';
  }
};