import { css } from 'styled-components';
export const setUiTransition = (property, timing) => {
  const computedTiming = timing || '150ms';
  const computedProperty = property || 'all';
  return css(["transition:", " ", " ease-out;"], computedProperty, computedTiming);
};
export const getPlaceholderStyles = styles => {
  return {
    '::-webkit-input-placeholder': Object.assign({}, styles),
    '::-moz-placeholder': Object.assign({
      opacity: 1
    }, styles),
    ':-ms-input-placeholder': Object.assign({}, styles)
  };
};