const sharedDisabledTokens = {
  backgroundColor: 'fill-tertiary-default',
  borderColor: 'border-primary-disabled',
  color: 'text-primary-disabled',
  cursor: 'auto'
};

/**
 * This helper retrieves variant tokens for a particular variant.
 *
 * @param variant - The current variant of the Button.
 *
 * @returns A new object containing tokens for the variant's idle, hover, active, and disabled states.

 */
export const getVariantTokens = variant => {
  const tokens = {
    primary: {
      idle: {
        backgroundColor: 'fill-brand-default',
        borderColor: 'fill-accent-orange-default',
        color: 'text-primary-on-fill-default'
      },
      hover: {
        backgroundColor: 'fill-brand-hover',
        borderColor: 'fill-brand-hover',
        color: 'text-primary-on-fill-default'
      },
      active: {
        backgroundColor: 'fill-brand-pressed',
        borderColor: 'fill-brand-pressed',
        color: 'text-primary-on-fill-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    'neutral-default': {
      idle: {
        backgroundColor: 'fill-tertiary-default',
        borderColor: 'fill-tertiary-default',
        color: 'text-primary-subtle'
      },
      hover: {
        backgroundColor: 'fill-tertiary-hover',
        borderColor: 'fill-tertiary-hover',
        color: 'text-primary-subtle'
      },
      active: {
        backgroundColor: 'fill-tertiary-pressed',
        borderColor: 'fill-tertiary-pressed',
        color: 'text-primary-subtle'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    'neutral-dark': {
      idle: {
        backgroundColor: 'fill-accent-neutral-default',
        borderColor: 'fill-accent-neutral-default',
        color: 'text-primary-on-fill-default'
      },
      hover: {
        backgroundColor: 'fill-accent-neutral-hover',
        borderColor: 'fill-accent-neutral-hover',
        color: 'text-primary-on-fill-default'
      },
      active: {
        backgroundColor: 'fill-accent-neutral-default',
        borderColor: 'fill-accent-neutral-default',
        color: 'text-primary-on-fill-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    'neutral-light': {
      idle: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-primary-default',
        color: 'text-primary-subtle'
      },
      hover: {
        backgroundColor: 'fill-tertiary-hover',
        borderColor: 'border-primary-default',
        color: 'text-primary-subtle'
      },
      active: {
        backgroundColor: 'fill-tertiary-pressed',
        borderColor: 'border-primary-subtle-pressed',
        color: 'text-primary-subtle'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    secondary: {
      idle: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'border-brand-default',
        color: 'text-brand-default'
      },
      hover: {
        backgroundColor: 'fill-accent-light-orange-subtle',
        borderColor: 'border-brand-default',
        color: 'text-brand-default'
      },
      active: {
        backgroundColor: 'fill-primary-subtle',
        borderColor: 'border-brand-default',
        color: 'text-brand-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    danger: {
      idle: {
        backgroundColor: 'fill-alert-default',
        borderColor: 'border-alert-default',
        color: 'text-primary-on-fill-default'
      },
      hover: {
        backgroundColor: 'fill-alert-hover',
        borderColor: 'fill-alert-hover',
        color: 'text-primary-on-fill-default'
      },
      active: {
        backgroundColor: 'fill-alert-pressed',
        borderColor: 'fill-alert-pressed',
        color: 'text-primary-on-fill-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    white: {
      idle: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'fill-surface-default',
        color: 'text-primary-default'
      },
      hover: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'fill-surface-default',
        color: 'text-primary-default'
      },
      active: {
        backgroundColor: 'fill-surface-default',
        borderColor: 'fill-surface-default',
        color: 'text-primary-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    ghost: {
      idle: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderColor: 'border-primary-subtle-default',
        color: 'text-primary-on-fill-default'
      },
      hover: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderColor: 'border-primary-subtle-default',
        color: 'text-primary-on-fill-default'
      },
      active: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderColor: 'border-primary-subtle-default',
        color: 'text-primary-on-fill-default'
      },
      disabled: Object.assign({}, sharedDisabledTokens)
    },
    transparent: {
      idle: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderColor: 'rgba(255, 255, 255, 0)',
        color: 'text-interactive-default'
      },
      hover: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderColor: 'rgba(255, 255, 255, 0)',
        color: 'text-interactive-default-hover'
      },
      active: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderColor: 'rgba(255, 255, 255, 0)',
        color: 'text-interactive-default-pressed'
      },
      disabled: Object.assign({}, sharedDisabledTokens, {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderColor: 'rgba(255, 255, 255, 0)'
      })
    }
  };
  return tokens[variant];
};

/**
 * This helper retrieves size tokens for a particular size (extra-small, small, medium).
 *
 * @param size - The current variant of the Button.
 * @param variant - The current variant of the Button.
 *
 * @returns A new object containing the typography, padding, and gap tokens for a particular size.

 */
export const getSizeTokens = (size, variant) => {
  // Tokens for common properties across variants
  const tokens = {
    medium: {
      gap: '200',
      paddingTop: '300',
      paddingBottom: '300',
      iconSize: 'icon-200',
      typography: 'heading-50',
      paddingLeft: '600',
      paddingRight: '600'
    },
    small: {
      gap: '200',
      paddingTop: '200',
      paddingBottom: '200',
      iconSize: 'icon-100',
      typography: 'detail-100',
      paddingLeft: '400',
      paddingRight: '400'
    },
    'extra-small': {
      gap: '100',
      paddingTop: '100',
      paddingBottom: '100',
      iconSize: 'icon-100',
      typography: 'detail-100',
      paddingLeft: '300',
      paddingRight: '300'
    }
  };

  // Specific adjustments for 'transparent' variant
  const transparentAdjustments = {
    medium: {
      typography: 'heading-50',
      paddingLeft: '300',
      paddingRight: '300'
    },
    small: {
      typography: 'detail-100',
      paddingLeft: '200',
      paddingRight: '200'
    },
    'extra-small': {
      typography: 'detail-100',
      paddingLeft: '100',
      paddingRight: '100'
    }
  };
  if (variant === 'transparent') {
    tokens[size] = Object.assign({}, tokens[size], transparentAdjustments[size]);
  }
  return tokens[size];
};