import { BREAKPOINTS, SCREEN_SIZES, MAX_COLUMNS } from './Grid.types';
const generateSizeStyles = (size, isOffset = false) => {
  if (size === 0 && !isOffset) {
    return {
      display: 'none'
    };
  }
  const property = isOffset ? 'marginLeft' : 'flexBasis';
  const percentage = size / MAX_COLUMNS * 100;
  return {
    [property]: `${percentage}%`
  };
};
export const generateResponsiveStyles = (breakpoints, isOffset = false) => {
  if (typeof breakpoints === 'number') {
    return generateSizeStyles(breakpoints, isOffset);
  }
  return SCREEN_SIZES.reduce((acc, screen) => {
    const breakpointValue = breakpoints[screen];
    if (breakpointValue !== undefined) {
      const mediaQuery = `@media (min-width: ${BREAKPOINTS[screen]}px)`;
      acc[mediaQuery] = generateSizeStyles(breakpointValue, isOffset);
    }
    return acc;
  }, {});
};