export const sizeToHeading = {
  xs: 'span',
  sm: 'h5',
  md: 'h4',
  lg: 'h2',
  xl: 'h1'
};
export const tagNameToPaddingLeft = {
  span: '500',
  h6: '500',
  h5: '600',
  h4: '700',
  h3: '700',
  h2: '800',
  h1: '800'
};
const tagNameToMarginBottom = {
  span: '200',
  h6: '400',
  h5: '400',
  h4: '400',
  h3: '400',
  h2: '400',
  h1: '400'
};
const tagNameToIconSize = {
  span: 'icon-100',
  h6: 'icon-100',
  h5: 'icon-200',
  h4: 'icon-300',
  h3: 'icon-400',
  h2: 'icon-500',
  h1: 'icon-500'
};
export const getPaddingLeft = (size, titleTagName) => {
  if (titleTagName) {
    return tagNameToPaddingLeft[titleTagName];
  }
  if (size) {
    return tagNameToPaddingLeft[sizeToHeading[size]];
  }
  return tagNameToPaddingLeft['span'];
};
export const getMarginBottom = (size, titleTagName) => {
  if (titleTagName) {
    return tagNameToMarginBottom[titleTagName];
  }
  if (size) {
    return tagNameToMarginBottom[sizeToHeading[size]];
  }
  return tagNameToMarginBottom['span'];
};
export const getIconSize = (size, titleTagName) => {
  if (titleTagName) {
    return tagNameToIconSize[titleTagName];
  }
  if (size) {
    return tagNameToIconSize[sizeToHeading[size]];
  }
  return tagNameToIconSize['span'];
};