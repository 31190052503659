export const getIconColor = use => {
  switch (use) {
    case 'danger':
      return 'fill-alert-default';
    case 'primary':
    case 'secondary':
      return 'fill-brand-default';
    case 'secondary-ghost':
      return 'fill-surface-default';
    case 'primary-white':
    case 'tertiary':
    case 'tertiary-light':
    case 'tertiary-extra-light':
      return 'fill-accent-neutral-default';
    case 'success':
      return 'fill-positive-default';
    case 'link':
    default:
      return 'fill-info-default';
  }
};
export const getIconSize = size => {
  switch (size) {
    case 'xs':
    case 'extra-small':
      return 'icon-500';
    case 'md':
    case 'medium':
      return 'icon-800';
    case 'sm':
    case 'small':
    default:
      return 'icon-700';
  }
};
const getInnerFlexSize = size => {
  switch (size) {
    case 'xs':
    case 'extra-small':
      return 'icon-500';
    case 'md':
    case 'medium':
      return 'icon-800';
    case 'sm':
    case 'small':
    default:
      return 'icon-700';
  }
};
export const getOuterFlexProps = (grow, layout, minHeight, renderedLabel) => {
  return Object.assign({}, grow && {
    blockSize: '100%',
    inlineSize: '100%'
  }, layout === 'centered' && {
    flexDirection: 'column',
    justifyContent: 'center'
  }, {
    position: 'relative',
    alignItems: 'center',
    minBlockSize: typeof minHeight === 'number' ? `${minHeight}px` : minHeight
  }, minHeight && !grow && {
    blockSize: 'icon-300'
  }, renderedLabel && layout === 'centered' && {
    rowGap: '200'
  }, renderedLabel && layout !== 'centered' && {
    columnGap: '200'
  });
};
export const getInnerFlexProps = size => {
  return {
    alignItems: 'center',
    justifyContent: 'center',
    blockSize: getInnerFlexSize(size),
    inlineSize: getInnerFlexSize(size)
  };
};
export const getRingColor = (theme, use) => {
  switch (use) {
    case 'on-dark':
      return 'rgba(255,255,255,0.5)';
    case 'danger':
      return theme.color['fill-accent-red-hover'];
    case 'primary':
    case 'secondary':
      return theme.color['fill-accent-orange-hover'];
    case 'secondary-ghost':
      return 'rgba(0, 0, 0, 0)';
    case 'primary-white':
    case 'tertiary':
    case 'tertiary-light':
    case 'tertiary-extra-light':
      return theme.color['text-primary-disabled'];
    case 'success':
      return theme.color['fill-positive-hover'];
    case 'link':
    default:
      return theme.color['fill-secondary-hover'];
  }
};
export const getIndicatorColor = use => {
  switch (use) {
    case 'danger':
      return 'fill-alert-default';
    case 'primary':
    case 'secondary':
      return 'fill-primary-default';
    case 'primary-white':
    case 'tertiary':
    case 'tertiary-light':
    case 'tertiary-extra-light':
      return 'fill-accent-neutral-default';
    case 'success':
      return 'fill-positive-default';
    case 'on-dark':
    case 'secondary-ghost':
      return 'fill-surface-default';
    case 'link':
    default:
      return 'fill-info-default';
  }
};